<template>
    <div class="main">
      <div class="overlay" :class="{'hidden':!isLoading}"></div>
      <div class="refresh" @click="getTransactions"><i class="far fa-sync"></i></div>
      <transition enter-active-class="fadeIn" leave-active-class="fadeOut">
      <div class="transaction-types animated-med" v-if="showTypeSelector">
        <div class="t-inner">
          <div class="t-type" v-for="(item,i) in transaction_types" :key="i" @click="addTransaction(item)">{{item}}</div>
          <div @click="showTypeSelector = false" style="width:calc(200% + 20px); text-align:center; background:#eaeaea; border:solid 1px #aeaeae; border-radius:5px; color:#808080;  padding:15px 0px;">
            <i class="fa fa-times"></i> Cancel 
          </div>
        </div>
      </div>
      </transition>
     
      <div class="c">
        <div style="display:flex; align-items:center; width:100%; padding-right:75px;">
          <div v-if="viewToday" class="remaining" :class="{good:remaining > 0}">${{remaining}}</div>
          <div v-if="!viewToday" class="remaining" :class="{good:remainingToday > 0}">${{remainingToday}}</div>
          <div style="margin-left:auto;" @click="viewToday = !viewToday"><i class="fa fa-sync"></i></div>
          <div style="margin-left:auto;" @click="$router.push('/graph')"><i class="fa fa-chart-line"></i></div>
        </div>
      
      <div class="input-c">
        <i class="far fa-dollar-sign"></i>
        <input class="main-input" type="number" readonly v-model="amount">
      </div>
      <div class="numpad">
        <div @click="press(1)" class="num">1</div>
        <div @click="press(2)" class="num">2</div>
        <div @click="press(3)" class="num">3</div>
        <div @click="press(4)" class="num">4</div>
        <div @click="press(5)" class="num">5</div>
        <div @click="press(6)" class="num">6</div>
        <div @click="press(7)" class="num">7</div>
        <div @click="press(8)" class="num">8</div>
        <div @click="press(9)" class="num">9</div>
        <div @click="press(-1)" class="num"><i class="fa fa-arrow-left"></i></div>
        <div @click="press(0)" class="num">0</div>
        <div @click="press(-2)" class="num submit"><i class="fa fa-check-circle"></i></div>
        
      </div>
    <div class="transaction-container">
      <div class="transaction" v-for="(item,i) in sortedTransactions" :key="i" :class="{'old':moment(item.date).format('MM-DD-YYYY') != moment().format('MM-DD-YYYY')}">{{moment(item.date).format('MMM DD')}} <span class="type-tag">{{item.type}}</span><span>${{Number(item.amount).toFixed(2)}} <div @click="removeTransaction(item._id)" class="trash"><i class="fal fa-trash"></i></div></span></div>
    </div>
      </div>
        
    </div>
  </template>
  
  <script>
  import moment from 'moment-timezone';
  import axios from 'axios';
  
  export default {
    name: 'DashBoard',
    data(){
      return {
        viewToday:false,
        moment,
        amount:0,
        isLoading:true,
        transactions:[],
        start_date:'06/11/2023',
        today:moment().format('MM/DD/YYYY'),
        socketInstance:null,
        transaction_types:['food','entertainment','misc','transportation'],
        showTypeSelector:false
      }
    },
    async mounted(){
      
      await this.getTransactions();

      
    },
    methods:{
      async removeTransaction(id){
        this.isLoading = true;
        try{
          let {data} = await axios.delete('/api/transactions/delete/' + id)
          //this.socketInstance.emit('transaction_update');
          this.isLoading = false;
          await this.getTransactions();
        }catch(e){
          window.alert('There was an error removing transaction');
          this.isLoading = false;
        }
      },
      async getTransactions(){
        this.isLoading = true;
        try{
            let {data} = await axios.get('/api/transactions/all');
            this.transactions = data.items;
            this.isLoading = false;
        }catch(e){
          this.isLoading = false;
          window.alert('There was an error Getting Transation');
        }
        
      },
     async addTransaction(type){
      this.isLoading = true;
      this.showTypeSelector = false;
        if(this.amount == 0){
          return;
        }
        let post = {
          amount:Number(this.amount),
          date:new Date(),
          type,
          
        }
        try{
          let {data} = await axios.post('/api/transactions/add', post)
         // this.socketInstance.emit('transaction_update');
          this.amount = 0;
          this.isLoading = false;
          await this.getTransactions();
        }catch(e){
          window.alert('There was an error Adding Transation');
          this.isLoading = false;
        }
        
      },
      press(num){
        if(num === -2){
          this.showTypeSelector = true;
          return;
        }
        if(num === -1){
          let s = this.amount.toString();
          s = s.substring(0, s.length-1);
          this.amount = Number(s);
          return;
        }
        
        this.amount = Number(this.amount.toString() + num.toString());
      }
    },
    computed:{
      rollover(){
        var now = new Date(this.today);
        let day_transactions;
        let rollover = 0;
        for (var d = new Date(this.start_date); d < now; d.setDate(d.getDate() + 1)) {
          let d_total = 30;
          day_transactions = this.transactions.filter((item)=>{
            return new Date(item.date) >= new Date(moment(new Date(d)).format('MM/DD/YYYY')) && new Date(item.date) <= new Date(moment(new Date(d)).add(1, 'days').format('MM/DD/YYYY'))
          })
          for(let i in day_transactions){
            d_total -= day_transactions[i].amount
          }
          rollover += d_total;
        }
        return rollover;
      },
      remaining(){
        let t = this.transactions.filter((item)=>{
          return new Date(item.date) >= new Date(this.today);
        })
        let total = 30;
        for(let i = 0; i < t.length;i++){
          total -= t[i].amount;
        } 
        return total + this.rollover
      },
      remainingToday(){
        let t = this.transactions.filter((item)=>{
          return new Date(item.date) >= new Date(this.today)
        })
        let total = 30;
        for(let i = 0; i < t.length;i++){
          total -= t[i].amount;
        } 
        return total
      },
      sortedTransactions(){
        function sortMe(array){
          return array.sort((a,b) =>{
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.date) - new Date(a.date);
          });
        }
        let arr = sortMe(this.transactions);
        return arr;
      }
    },
    components: {
    }
  }
  </script>
  <style scoped lang="scss">
  .transaction-types{
    position:fixed;
    top:0;
    left:0;
    background: #f5f5f5cc;
    height:100vh;
    width:100%;
    z-index: 100;
    display:flex;
    align-items: center;
    justify-content: center;
    .t-inner{
      padding:15px;
      display:grid;
      grid-template-columns: 1fr 1fr;
      gap:25px;
      .t-type{
        border-radius: 5px;
        border:solid 1px darken(#40ce7b, 10%);
        background:#40ce7b;
        color:#fff;
        font-size:15pt;
        padding:0px 15px;
        font-weight: bold;
        height:70px;
        display:flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .type-tag{
    font-size:9pt; 
    text-align: left;
    display:inline-block;
    margin-right:auto;
    margin-left:15px !important;
    background:#40ce7b;
    color:#fff;
    padding:5px 9px;
    border-radius:100px;
  
  }
  .refresh{
    position:fixed;
    display:flex;
    align-items: center;
    justify-content: center;
    top:15px;
    right:15px;
    height:45px;
    width:45px;
    background:#40ce7b;
    border-radius: 100px;
    color:#fff;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.4);
    font-size:12pt;
  }
  .remaining{
    font-size:45pt;
    font-weight: bold;
    margin-bottom:15px;
    display:flex;
    align-items: center;
    justify-self: center;
    color:#f75c5c;
    &.good{
      color:#40ce7b
    }
  
  }
  .transaction-container{
    margin-top:15px;
    width:100%;
    max-width:500px;
    max-height:200px;
    overflow: auto;
    .transaction{
      background:#fff;
      font-size:15pt;
      border:solid 1px #aeaeae;
      padding:15px;
      border-radius: 5px;
      display:flex;
      align-items: center;
      height:46px;
      margin-bottom:10px;
      &.old{
        opacity: .5;
      }
      span{
        margin-left:auto;
        font-weight: bold;
        white-space:nowrap;
  
        align-items:center;
        display:flex;
        .trash{
          display:inline-flex;
          align-items: center;
          justify-content: center;
          margin-left:10px;
          height:25px;
          border-radius: 4px;
          background:#eaeaea;
          border:solid 1px #aeaeae;
          width:25px;
          i{
            font-size:8pt;
          }
        }
  
      }
    }
  }
  .c{
    display:flex;
    flex-direction: column;
    align-items: center;
  }
  .numpad{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap:15px;
    margin-top:15px;
    width:100%;
    .num{
      background:#fff;
      font-size:45pt;
      display:flex;
      align-items:center;
      justify-content: center;
      font-weight: bold;
      border:solid 1px #aeaeae;
      border-radius: 5px;
      user-select: none;
      &.submit{
        background:#40ce7b;
        color:#fff;
        font-size:25pt;
        border:solid 1px darken(#40ce7b, 10%);
      }
      cursor: default;
      -webkit-appearance: none;
      touch-action: none;
      &:active{
        background:#ff9900;
      }
      &:focus{
        background:#ff9900;
      }
    }
  }
  .main{
    height:100vh;
    width:100%;
    background:#f5f5f5;
    display:flex;
    align-items: flex-start;
    position:relative;
    max-width:100%;
    justify-content: center;
    padding:15px;
    
    .main-input{
      border:solid 1px #aeaeae;
      border-radius: 5px;
      font-size:45pt;
      font-weight:bold;
      padding:25px;
      box-sizing:border-box;
      padding-left:55px;
      width:100%;
       text-align:center;
       outline:none;
      
    }
    .input-c{
      position:relative;
      
      width:100%;
     
      max-width:500px;
      i{
        position: absolute;    
        font-size:45pt;
        top:50%;
        transform:translateY(-50%);
        left:10px;
      }
    }
  }
  .overlay{
    background:#3080e050;
    transition:all .2s ease;
    height:100%;
    width:100%;
    position:fixed;
    top:0;
    left:0;
    pointer-events: none;
    z-index: 10;
    opacity: 1;
    &.hidden{
      opacity: 0;
    }
  }
  </style>
  