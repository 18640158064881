import { createRouter, createWebHistory } from 'vue-router'
import Dash from '@/views/Dash';
import Graph from '@/views/Graph';

const routes = [
  {
    path: '/',
    name: 'Dash',
    component: Dash
  },
 {
    path: '/graph',
    name: 'Graph',
    component: Graph
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
