<template>
  <div class="main">
    <div class="overlay" :class="{'hidden':!isLoading}"></div>
    <div class="refresh" @click="getTransactions"><i class="far fa-sync"></i></div>
    <transition enter-active-class="fadeIn" leave-active-class="fadeOut">
    <div class="transaction-types animated-med" v-if="showTypeSelector">
      <div class="t-inner">
        <div class="t-type" v-for="(item,i) in transaction_types" :key="i" @click="addTransaction(item)">{{item}}</div>
        <div @click="showTypeSelector = false" style="width:calc(200% + 20px); text-align:center; background:#eaeaea; border:solid 1px #aeaeae; border-radius:5px; color:#808080;  padding:15px 0px;">
          <i class="fa fa-times"></i> Cancel 
        </div>
        
      </div>
    </div>
    </transition>
   
    <div class="c">
      <div style="display:flex; align-items:center; width:100%;">
        <div @click="$router.push('/');"><i class="fa fa-arrow-left"></i></div>
      </div>
    
      <canvas style="width:100%;height:70vh;" ref="chart"></canvas>
    
  
    </div>
      
  </div>
</template>

<script>
import moment from 'moment-timezone';
import Chart from 'chart.js/auto';
import axios from 'axios';
import Utils from 'chart.js/auto';
// import { getRelativePosition } from 'chart.js/helpers';

export default {
  name: 'ChartJs',

  data(){
    return {
      options: {
        responsive: true
      },
      chart:null,
      transactions:[],
      isLoading:false
    }
  },
  async mounted(){
    this.$nextTick(async ()=>{
      
      await this.getTransactions();

      
    })
    
  },
  methods:{

    async getTransactions(){
      this.isLoading = true;
      try{
          let {data} = await axios.get('/api/transactions/all');
          this.transactions = data.items;
          this.isLoading = false;
          
      }catch(e){
        this.isLoading = false;
        window.alert('There was an error Getting Transation');
      }
      let types = ['food', 'misc','entertainment','transportation'];
      let colors = ['#ff9900', '#ff0099','#00ff33', '#3080e8']
      let months = [];
      for(let i = 0; i < 12; i++){
        months[i] = moment().add(i * -1, 'months').format('MMM')
      }

  
      
      months.reverse();
            this.chart = new Chart(this.$refs.chart, {
            type: 'bar',
            data: {
              labels: months,
              
              datasets:
                types.map((type, i)=>{
                return {
                label:type,
                backgroundColor:colors[i],
                data: months.map((item)=>{
                  let date = moment().set('month', item);
                  return this.transactions.filter((trans)=>{
                    return moment(trans.date).isSame(date, 'month');
                  }).filter((f)=>f.type == type).map((item5)=>{
                    return item5.amount
                  }).reduce((item3,item4)=>item3+item4, 0)
                  
                }),
                
              }
              })
            
            },
            options: {
              responsive:true,
              scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true
            }
          }
            }
          })
      
    },
  },
  computed:{
    
  }
}
</script>
<style scoped lang="scss">
.transaction-types{
  position:fixed;
  top:0;
  left:0;
  background: #f5f5f5cc;
  height:100vh;
  width:100%;
  z-index: 100;
  display:flex;
  align-items: center;
  justify-content: center;
  .t-inner{
    padding:15px;
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap:25px;
    .t-type{
      border-radius: 5px;
      border:solid 1px darken(#40ce7b, 10%);
      background:#40ce7b;
      color:#fff;
      font-size:15pt;
      padding:0px 15px;
      font-weight: bold;
      height:70px;
      display:flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.type-tag{
  font-size:9pt; 
  text-align: left;
  display:inline-block;
  margin-right:auto;
  margin-left:15px !important;
  background:#40ce7b;
  color:#fff;
  padding:5px 9px;
  border-radius:100px;

}
.refresh{
  position:fixed;
  display:flex;
  align-items: center;
  justify-content: center;
  top:15px;
  right:15px;
  height:45px;
  width:45px;
  background:#40ce7b;
  border-radius: 100px;
  color:#fff;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.4);
  font-size:12pt;
}
.remaining{
  font-size:45pt;
  font-weight: bold;
  margin-bottom:15px;
  display:flex;
  align-items: center;
  justify-self: center;
  color:#f75c5c;
  &.good{
    color:#40ce7b
  }

}
.transaction-container{
  margin-top:15px;
  width:100%;
  max-width:500px;
  max-height:200px;
  overflow: auto;
  .transaction{
    background:#fff;
    font-size:15pt;
    border:solid 1px #aeaeae;
    padding:15px;
    border-radius: 5px;
    display:flex;
    align-items: center;
    height:46px;
    margin-bottom:10px;
    &.old{
      opacity: .5;
    }
    span{
      margin-left:auto;
      font-weight: bold;
      white-space:nowrap;

      align-items:center;
      display:flex;
      .trash{
        display:inline-flex;
        align-items: center;
        justify-content: center;
        margin-left:10px;
        height:25px;
        border-radius: 4px;
        background:#eaeaea;
        border:solid 1px #aeaeae;
        width:25px;
        i{
          font-size:8pt;
        }
      }

    }
  }
}
.c{
  display:flex;
  width:100%;
  max-width:1200px;
  flex-direction: column;
  align-items: center;
}
.numpad{
  display:grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap:15px;
  margin-top:15px;
  width:100%;
  .num{
    background:#fff;
    font-size:45pt;
    display:flex;
    align-items:center;
    justify-content: center;
    font-weight: bold;
    border:solid 1px #aeaeae;
    border-radius: 5px;
    user-select: none;
    &.submit{
      background:#40ce7b;
      color:#fff;
      font-size:25pt;
      border:solid 1px darken(#40ce7b, 10%);
    }
    cursor: default;
    -webkit-appearance: none;
    touch-action: none;
    &:active{
      background:#ff9900;
    }
    &:focus{
      background:#ff9900;
    }
  }
}
.main{
  height:100vh;
  width:100%;
  background:#f5f5f5;
  display:flex;
  align-items: flex-start;
  position:relative;
  max-width:100%;
  justify-content: center;
  padding:15px;
  
  .main-input{
    border:solid 1px #aeaeae;
    border-radius: 5px;
    font-size:45pt;
    font-weight:bold;
    padding:25px;
    box-sizing:border-box;
    padding-left:55px;
    width:100%;
     text-align:center;
     outline:none;
    
  }
  .input-c{
    position:relative;
    
    width:100%;
   
    max-width:500px;
    i{
      position: absolute;    
      font-size:45pt;
      top:50%;
      transform:translateY(-50%);
      left:10px;
    }
  }
}
.overlay{
  background:#3080e050;
  transition:all .2s ease;
  height:100%;
  width:100%;
  position:fixed;
  top:0;
  left:0;
  pointer-events: none;
  z-index: 10;
  opacity: 1;
  &.hidden{
    opacity: 0;
  }
}
</style>
